import { NgTemplateOutlet } from '@angular/common';
import { Component, inject } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { RouterOutlet } from '@angular/router';

import { ClickOutsideDirective } from '@base/directives/click-outside/click-outside.directive';
import { FooterComponent } from '@layouts/main/footer/footer.component';
import { HeaderComponent } from '@layouts/main/header/header.component';
import { RightSideNavService } from '@services/right-side-nav/right-side-nav.service';

@Component({
  selector: 'main-layout',
  standalone: true,
  imports: [NgTemplateOutlet, RouterOutlet, ClickOutsideDirective, FooterComponent, HeaderComponent],
  templateUrl: './main-layout.component.html',
  styleUrls: ['./main-layout.component.scss']
})
export class MainLayoutComponent {
  readonly rightSideNavService = inject(RightSideNavService);

  readonly rightSideNavOpen = toSignal(this.rightSideNavService.opened$, { requireSync: true });

  handleOutsideClick(event: MouseEvent): void {
    const ignoredClasses: string[] = ['block-id'];

    if (this.rightSideNavService.template && !ignoredClasses.some(item => (event.target as HTMLElement).classList.contains(item))) {
      this.rightSideNavService.close();
    }
  }
}
