import { inject, Injectable } from '@angular/core';

import { User } from '@models/classes/user.model';
import { AuthService } from '@services/auth/auth.service';

@Injectable({
  providedIn: 'root'
})
export class MeResolver {
  private readonly _auth: AuthService = inject(AuthService);

  async resolve(): Promise<Promise<User> | User> {
    const isAuthenticated = await this._auth.isAuthenticated();
    if (isAuthenticated) {
      return this._auth.me || this._auth.getMe();
    } else {
      return null;
    }
  }
}
