import { registerLocaleData } from '@angular/common';
import localeDe from '@angular/common/locales/de';
import localeDeExtra from '@angular/common/locales/extra/de';
import { Component, inject } from '@angular/core';
import { MatIconRegistry, SafeResourceUrlWithIconOptions } from '@angular/material/icon';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

import { InitPathService } from '@services/init-path/init-path.service';
import { BaseComponent } from '@shared/components/base/base.component';

@Component({
  template: ''
})
export abstract class AppAbstractComponent extends BaseComponent {
  private readonly _iconRegistry = inject(MatIconRegistry);
  private readonly _sanitizer = inject(DomSanitizer);
  private readonly _initPathService = inject(InitPathService);

  constructor() {
    super();
    this._initPathService.update();
    this._iconRegistry.addSvgIconResolver((name: string, namespace: string): SafeResourceUrl | SafeResourceUrlWithIconOptions | null => {
      const path: string = `assets/img/svg/${namespace ? `${namespace}/` : ''}${name}.svg`;
      return this._sanitizer.bypassSecurityTrustResourceUrl(`./${path}`);
    });
    registerLocaleData(localeDe, 'de-DE', localeDeExtra);
  }
}
