import { Component, inject } from '@angular/core';
import { MatToolbarModule } from '@angular/material/toolbar';
import { TranslateModule } from '@ngx-translate/core';

import { NavigationComponent } from '@layouts/main/navigation/navigation.component';
import { ProfileBadgeComponent } from '@layouts/main/profile-badge/profile-badge.component';
import { RoleSwitcherComponent } from '@layouts/main/role-switcher/role-switcher.component';
import { AuthService } from '@services/auth/auth.service';

@Component({
  selector: 'app-header',
  standalone: true,
  imports: [TranslateModule, MatToolbarModule, NavigationComponent, ProfileBadgeComponent, RoleSwitcherComponent],
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent {
  private readonly _authService = inject(AuthService);

  async navigateToHome(e: Event): Promise<void> {
    e.preventDefault();
    this._authService.routeToHome();
  }
}
