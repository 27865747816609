import { inject, Injectable } from '@angular/core';
import { UrlTree } from '@angular/router';
import { Observable } from 'rxjs';

import { Theme } from '@models/enums/theme.enum';
import { AuthService } from '@services/auth/auth.service';
import { ThemeService } from '@services/theme/theme.service';

@Injectable({
  providedIn: 'root'
})
export class UnAuthGuard {
  private readonly _themeService: ThemeService = inject(ThemeService);
  private readonly _auth: AuthService = inject(AuthService);

  canActivate(): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    this._auth.clearTokens();
    this._themeService.setTheme(Theme.light);

    return true;
  }
}
