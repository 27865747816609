import { Component, Signal, inject } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { MatIconModule } from '@angular/material/icon';
import { RouterLink, RouterLinkActive } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { map } from 'rxjs';

import { AppRoutingPaths } from '@base/app-routing.paths';
import { AppIcon } from '@models/enums/app.icons';
import { UserRole } from '@models/enums/user-role.enum';
import { INavLink } from '@models/interfaces/nav-link.interface';
import { IRoleSwitchItem, RoleSwitcherService } from '@services/role-switcher/role-switcher.service';
import { BaseComponent } from '@shared/components/base/base.component';

const USER_LINKS: INavLink[] = [
  {
    name: 'DASHBOARD',
    route: AppRoutingPaths.CLIENT_DASHBOARD,
    icon: AppIcon.DASHBOARD,
    classIcon: 'fill'
  }
];

const COACH_LINKS: INavLink[] = [
  {
    name: 'DASHBOARD',
    route: AppRoutingPaths.COACH_DASHBOARD,
    icon: AppIcon.DASHBOARD,
    classIcon: 'fill'
  }
];

const ADMIN_LINKS: INavLink[] = [
  {
    name: 'DASHBOARD',
    route: AppRoutingPaths.ADMIN_DASHBOARD,
    icon: AppIcon.ADMIN_DASHBOARD,
    classIcon: 'fill'
  },
  {
    name: 'MODULE',
    route: AppRoutingPaths.ADMIN_MODULES,
    icon: AppIcon.ADMIN_MODULES,
    classIcon: 'stroke'
  },
  {
    name: 'USER',
    route: AppRoutingPaths.ADMIN_USERS,
    icon: AppIcon.ADMIN_USERS,
    classIcon: 'fill'
  },
  {
    name: 'ORGANIZATION',
    route: AppRoutingPaths.ADMIN_ORGANIZATIONS,
    icon: AppIcon.ORGANIZATION,
    classIcon: 'fill'
  },
  {
    name: 'NOTIFICATION',
    route: AppRoutingPaths.ADMIN_NOTIFICATION,
    icon: AppIcon.ADMIN_NOTIFICATIONS,
    disabled: true,
    classIcon: 'stroke'
  }
];

@Component({
  selector: 'app-navigation',
  standalone: true,
  imports: [TranslateModule, RouterLink, RouterLinkActive, MatIconModule],
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.scss']
})
export class NavigationComponent extends BaseComponent {
  private readonly _roleSwitcher: RoleSwitcherService = inject(RoleSwitcherService);

  // Observables
  private readonly _links$ = this._roleSwitcher.SELECTED_ITEM$.pipe(
    map((item: IRoleSwitchItem) => {
      switch (item?.value) {
        case UserRole.user:
          return USER_LINKS;
        case UserRole.coach:
          return COACH_LINKS;
        case UserRole.admin:
          return ADMIN_LINKS;
        default:
          return [];
      }
    })
  );
  private readonly _pageKey$ = this._roleSwitcher.SELECTED_ITEM$.pipe(
    map((item: IRoleSwitchItem) => {
      switch (item?.value) {
        case UserRole.user:
          return 'USER.HEADER.';
        case UserRole.coach:
          return 'COACH.HEADER.';
        case UserRole.admin:
          return 'ADMIN.HEADER.';
        default:
          return '';
      }
    })
  );

  // Computed signals
  readonly links: Signal<INavLink[]> = toSignal(this._links$, { initialValue: [] });
  readonly pageKey: Signal<string> = toSignal(this._pageKey$, { initialValue: '' });
}
