import { Component, inject } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { MatIcon } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { RouterLink } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';

import { AppRoutingPaths } from '@base/app-routing.paths';
import { AppIcon } from '@models/enums/app.icons';
import { AuthService } from '@services/auth/auth.service';

@Component({
  selector: 'app-profile-badge',
  standalone: true,
  imports: [RouterLink, TranslateModule, MatMenuModule, MatIcon],
  templateUrl: './profile-badge.component.html',
  styleUrls: ['./profile-badge.component.scss']
})
export class ProfileBadgeComponent {
  private readonly _authService = inject(AuthService);

  readonly AppIcon = AppIcon;
  readonly AppRoutingPaths = AppRoutingPaths;

  readonly user = toSignal(this._authService.me$, { requireSync: true });

  logout(): void {
    this._authService.logout();
  }
}
