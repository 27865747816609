import { Component, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { combineLatest, switchMap, tap } from 'rxjs';
import { map } from 'rxjs/operators';

import { AppAbstractComponent } from '@misc/abstracts/app.abstract.component';
import { AuthService } from '@services/auth/auth.service';
import { IRoleSwitchItem, RoleSwitcherService } from '@services/role-switcher/role-switcher.service';
import { StonlyService } from '@services/stonly/stonly.service';
import { User } from './models/classes/user.model';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent extends AppAbstractComponent {
  private readonly _stonlyService = inject(StonlyService);
  private readonly _authService = inject(AuthService);
  private readonly _roleSwitcher = inject(RoleSwitcherService);

  constructor() {
    super();
    combineLatest([this._authService.me$, this._roleSwitcher.SELECTED_ITEM$])
      .pipe(
        takeUntilDestroyed(),
        tap(() => this._stonlyService.init()),
        switchMap(([user]: [User, IRoleSwitchItem]) =>
          this._stonlyService.isScriptLoaded$.pipe(
            takeUntilDestroyed(this._destroyRef),
            map((isLoaded: boolean) => ({
              user,
              isLoaded
            }))
          )
        )
      )
      .subscribe(({ user, isLoaded }: { user: User; isLoaded: boolean }): void => {
        if (isLoaded && !!user) {
          this._stonlyService.identifyUser(user.id);
        }
      });
  }
}
