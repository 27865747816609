<footer class="footer">
  @if (!isAuthorized()) {
    <img [alt]="'MISC.SPONSOR_LOGO' | translate" class="footer__sponsor-logo" src="/assets/img/png/sponsor-logo.png" />
  }
  <div class="footer__copy">
    <span class="body-5">&copy; {{ copyrightYearsLabel() }} {{ 'LAYOUT.COPYRIGHT' | translate }}</span>
  </div>
  <div class="footer__legal">
    @for (link of links; track link.route) {
      @if (link?.external) {
        <a [href]="link.route" target="_blank" class="footer__legal-item">
          <span class="body-5">{{ link.name | translate }}</span>
        </a>
      } @else {
        <a [routerLink]="link.route" class="footer__legal-item">
          <span class="body-5">{{ link.name | translate }}</span>
        </a>
      }
    }
  </div>
</footer>
