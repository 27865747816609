import { inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Route, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';

import { UserRole } from '@models/enums/user-role.enum';
import { RoleSwitcherService } from '@services/role-switcher/role-switcher.service';

export interface IRoleGuardParams {
  roles: UserRole[];
  redirectTo: string[];
  shouldSkipErrorNotification?: boolean;
}

@Injectable({
  providedIn: 'root'
})
export class RoleGuard {
  private readonly _roleSwitcher: RoleSwitcherService = inject(RoleSwitcherService);
  private readonly _router: Router = inject(Router);
  private readonly _notification: ToastrService = inject(ToastrService);
  private readonly _translate: TranslateService = inject(TranslateService);

  canLoad(route: Route): Promise<boolean> | boolean {
    return this._isRoleAllowed(route?.data?.roleGuardParams ?? {});
  }

  canActivate(route: ActivatedRouteSnapshot): Promise<boolean> | boolean {
    return this._isRoleAllowed(route?.data?.roleGuardParams ?? {});
  }

  canActivateChild(childRoute: ActivatedRouteSnapshot): Promise<boolean> | boolean {
    return this._isRoleAllowed(childRoute?.data?.roleGuardParams ?? {});
  }

  private _isRoleAllowed({ redirectTo, roles, shouldSkipErrorNotification }: IRoleGuardParams): Promise<boolean> | boolean {
    const isRoleAllowed: boolean = Boolean((roles ?? [])?.find((role: UserRole): boolean => this._roleSwitcher.selectedRole === role));

    if (!isRoleAllowed) {
      if (!shouldSkipErrorNotification) {
        this._notification.error(this._translate.instant('BACKEND_ERRORS.ACCESS_DENIED'));
      }
      return redirectTo?.length ? this._router.navigate(redirectTo) : false;
    }

    return isRoleAllowed;
  }
}
