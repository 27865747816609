import { Component, computed, inject } from '@angular/core';
import { RouterLink } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';

import { toSignal } from '@angular/core/rxjs-interop';
import { AppRoutingPaths } from '@base/app-routing.paths';
import { INavLink } from '@models/interfaces/nav-link.interface';
import { AuthService } from '@services/auth/auth.service';

@Component({
  selector: 'app-footer',
  standalone: true,
  imports: [RouterLink, TranslateModule],
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent {
  private readonly _authService = inject(AuthService);

  readonly links: INavLink[] = [
    {
      route: `${AppRoutingPaths.CDN_IMPRINT}`,
      name: 'LAYOUT.IMPRINT',
      external: true
    },
    {
      route: `${AppRoutingPaths.CDN_DATA_PRIVACY}`,
      name: 'LAYOUT.PRIVACY',
      external: true
    }
  ];

  readonly isAuthorized = toSignal(this._authService.isAuthorized$, { initialValue: false });

  readonly copyrightYearsLabel = computed<string>(() => {
    const startYear = 2022;
    const currentYear = new Date().getFullYear();
    return currentYear - startYear == 0 ? `${currentYear}` : `${startYear}-${currentYear}`;
  });
}
