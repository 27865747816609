@if (isAvailable) {
  <button
    (menuClosed)="isMenuOpened.set(false)"
    (menuOpened)="isMenuOpened.set(true)"
    [class.role-switcher_opened]="isMenuOpened()"
    [disabled]="isDisabled"
    [matMenuTriggerFor]="menu"
    class="role-switcher"
  >
    <mat-icon [svgIcon]="selectedItem?.icon" class="role-switcher__icon"></mat-icon>
    <span class="role-switcher__label">{{ selectedItem?.label }}</span>
    <mat-icon class="role-switcher__arrow" [svgIcon]="AppIcon.CHEVRON"></mat-icon>
  </button>
}

<mat-menu #menu="matMenu" class="role-switcher__menu">
  @for (item of items; track item.value) {
    <button (click)="setRole(item)" mat-menu-item>
      <mat-icon [svgIcon]="item.icon"></mat-icon>
      {{ item.label | translate }}
    </button>
  }
</mat-menu>
