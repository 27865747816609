<div class="main-layout layout-base">
  <app-header></app-header>
  <div class="main-layout__content layout-base__content">
    <router-outlet class="main-layout__router-outlet layout-base__router-outlet"></router-outlet>

    @if (rightSideNavService.template && rightSideNavOpen()) {
      <div class="main-layout__right-side" (clickOutside)="handleOutsideClick($event)">
        <ng-container *ngTemplateOutlet="rightSideNavService.template"></ng-container>
      </div>
    }
  </div>
  <app-footer></app-footer>
</div>
