<header class="header">
  <mat-toolbar>
    <div class="header__left-panel">
      <a (click)="navigateToHome($event)" class="header__logo" href="javascript:void(0)">
        <p class="title-2 header__sub-header">{{ 'LOGO_FIRST' | translate }}</p>
        <p class="title-1 header__second-header">{{ 'LOGO_SECOND' | translate }}</p>
      </a>
      <app-role-switcher class="header__role-switcher"></app-role-switcher>
    </div>
    <span class="spacer"></span>
    <app-navigation></app-navigation>
    <app-profile-badge></app-profile-badge>
  </mat-toolbar>
</header>
