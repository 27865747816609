import { Type } from '@angular/core';
import { Routes } from '@angular/router';
import { AppRoutingPaths } from '@base/app-routing.paths';
import { UnAuthGuard } from '@guards/unauth.guard';
import { AuthModule } from '@modules/auth/auth.module';
import { MainLayoutComponent } from '@layouts/main/main-layout.component';
import { MeResolver } from '@resolvers/me/me.resolver';
import { MainModule } from '@modules/main/main.module';
import { UserRole } from '@models/enums/user-role.enum';
import { IRoleGuardParams, RoleGuard } from '@guards/role/role.guard';
import { AuthGuard } from '@guards/auth/auth.guard';
import { PdfModule } from '@modules/main/client/pdf/pdf.module';

export const AppRouting: Routes = [
  {
    path: AppRoutingPaths.AUTH,
    component: MainLayoutComponent,
    canActivate: [UnAuthGuard],
    loadChildren: (): Promise<Type<AuthModule>> =>
      import('@modules/auth/auth.module').then((m: { AuthModule: Type<AuthModule> }): Type<AuthModule> => m.AuthModule)
  },

  // INFO: Used by the backend for generating pdfs
  {
    path: 'pdf',
    loadChildren: () =>
      import('@modules/main/client/pdf/pdf.module').then((m: { PdfModule: Type<PdfModule> }): Type<PdfModule> => m.PdfModule)
  },
  {
    path: '',
    resolve: { me: MeResolver },
    canLoad: [AuthGuard, RoleGuard],
    component: MainLayoutComponent,
    runGuardsAndResolvers: 'always',
    data: {
      roleGuardParams: {
        redirectTo: ['', AppRoutingPaths.AUTH, AppRoutingPaths.AUTH_COGNITO_LOGIN],
        roles: [UserRole.user, UserRole.coach, UserRole.admin],
        shouldSkipErrorNotification: true
      } as IRoleGuardParams
    },
    loadChildren: (): Promise<Type<MainModule>> =>
      import('@modules/main/main.module').then((m: { MainModule: Type<MainModule> }): Type<MainModule> => m.MainModule)
  },
  {
    path: '**',
    redirectTo: ''
  }
];
