import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { AppRouting } from './app-routing.routes';
import { AuthService } from '@services/auth/auth.service';

@NgModule({
  imports: [
    RouterModule.forRoot(AppRouting, {
      initialNavigation: 'enabledBlocking'
    })
  ],
  providers: [AuthService],
  exports: [RouterModule]
})
export class AppRoutingModule {}
