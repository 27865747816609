@if (user()) {
  <button class="profile-badge" [matMenuTriggerFor]="menu">
    @if (user()?.picture) {
      <img [src]="user()?.picture" class="profile-badge__image" alt="Profile badge image" />
    } @else {
      <div class="profile-badge__image">
        <mat-icon>person</mat-icon>
      </div>
    }
    @if (user()?.firstName) {
      <p class="profile-badge__name body-2">{{ user()?.firstName }}</p>
    }
  </button>
}

<mat-menu #menu="matMenu" class="profile-badge__menu" xPosition="before">
  <button mat-menu-item [routerLink]="AppRoutingPaths.PROFILE.toString()">
    <mat-icon [svgIcon]="AppIcon.TOOLS"></mat-icon>
    <span>{{ 'BUTTON_NAME.PROFILE' | translate }}</span>
  </button>
  <button mat-menu-item (click)="logout()">
    <mat-icon [svgIcon]="AppIcon.LOG_OUT"></mat-icon>
    <span>{{ 'BUTTON_NAME.LOGOUT' | translate }}</span>
  </button>
</mat-menu>
