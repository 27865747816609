import { HTTP_INTERCEPTORS, HttpClient, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { APP_INITIALIZER, LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppHttpInterceptor, DEFAULT_TIMEOUT } from '@misc/app.http.interceptor';
import { AppInitializerFactory, HttpLoaderFactory } from '@misc/app.initializer';
import { NotFoundModule } from '@modules/common/not-found/not-found.module';
import { TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';
import { AuthService } from '@services/auth/auth.service';
import { CognitoService } from '@services/cognito/cognito.service';
import { RightSideNavService } from '@services/right-side-nav/right-side-nav.service';
import { SharedModule } from '@shared/shared.module';
import { LoggerModule, NgxLoggerLevel } from 'ngx-logger';
import { QuillModule } from 'ngx-quill';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

@NgModule({
  declarations: [AppComponent],
  bootstrap: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    SharedModule,
    NotFoundModule,
    QuillModule.forRoot(),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    LoggerModule.forRoot({
      serverLoggingUrl: '/api/logs',
      level: NgxLoggerLevel.DEBUG,
      serverLogLevel: NgxLoggerLevel.ERROR
    })
  ],
  providers: [
    AuthService,
    CognitoService,
    RightSideNavService,
    { provide: APP_INITIALIZER, useFactory: AppInitializerFactory, multi: true, deps: [TranslateService] },
    { provide: HTTP_INTERCEPTORS, useClass: AppHttpInterceptor, multi: true },
    { provide: DEFAULT_TIMEOUT, useValue: 30000 },
    { provide: LOCALE_ID, useValue: 'de-DE' },
    provideHttpClient(withInterceptorsFromDi())
  ]
})
export class AppModule {}
