import { inject, Injectable } from '@angular/core';
import { AuthService } from '@services/auth/auth.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard {
  private readonly _authService: AuthService = inject(AuthService);

  canLoad(): Promise<boolean> {
    return this._isAuthenticated();
  }

  canActivate(): Promise<boolean> {
    return this._isAuthenticated();
  }

  private async _isAuthenticated(): Promise<boolean> {
    const isAuthenticated = await this._authService.isAuthenticated();

    if (!isAuthenticated) {
      this._authService.routeToLogin();
    }
    return isAuthenticated;
  }
}
