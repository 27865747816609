import { Component, inject, signal } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { TranslateModule } from '@ngx-translate/core';

import { AppIcon } from '@models/enums/app.icons';
import { UserRole } from '@models/enums/user-role.enum';
import { AuthService } from '@services/auth/auth.service';
import { IRoleSwitchItem, RoleSwitcherService } from '@services/role-switcher/role-switcher.service';
import { BaseComponent } from '@shared/components/base/base.component';

@Component({
  selector: 'app-role-switcher',
  standalone: true,
  imports: [TranslateModule, MatMenuModule, MatIconModule],
  templateUrl: './role-switcher.component.html',
  styleUrls: ['./role-switcher.component.scss']
})
export class RoleSwitcherComponent extends BaseComponent {
  private readonly _authService: AuthService = inject(AuthService);
  private readonly _roleSwitcher: RoleSwitcherService = inject(RoleSwitcherService);
  readonly AppIcon = AppIcon;

  readonly isMenuOpened = signal<boolean>(false);

  get isAvailable(): boolean {
    return !!this._authService.me && (this.items.length > 0 || this._authService.myRole === UserRole.admin);
  }

  get isDisabled(): boolean {
    return this.items.length < 1;
  }

  get selectedItem(): IRoleSwitchItem {
    return this._roleSwitcher.selectedItem;
  }

  get items(): IRoleSwitchItem[] {
    return this._roleSwitcher.ROLE_ITEMS;
  }

  setRole(item: IRoleSwitchItem): void {
    this._roleSwitcher.setSelectedItem(item);
  }
}
